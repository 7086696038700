/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AmplifyCreditsPurchaseResponse } from '../models/amplify-credits-purchase-response';
import { AmplifyCreditsPurchaseSessionCreatedResponse } from '../models/amplify-credits-purchase-session-created-response';
import { AmplifyCreditsRateSummaryResponse } from '../models/amplify-credits-rate-summary-response';
import { amplifyPost } from '../fn/amplify/amplify-post';
import { AmplifyPost$Params } from '../fn/amplify/amplify-post';
import { AmplifyPostResponse } from '../models/amplify-post-response';
import { createAmplifyCreditsPurchaseSession } from '../fn/amplify/create-amplify-credits-purchase-session';
import { CreateAmplifyCreditsPurchaseSession$Params } from '../fn/amplify/create-amplify-credits-purchase-session';
import { getAmplifyCreditsHasPurchasedAtLeastOnce } from '../fn/amplify/get-amplify-credits-has-purchased-at-least-once';
import { GetAmplifyCreditsHasPurchasedAtLeastOnce$Params } from '../fn/amplify/get-amplify-credits-has-purchased-at-least-once';
import { getAmplifyCreditsRates } from '../fn/amplify/get-amplify-credits-rates';
import { GetAmplifyCreditsRates$Params } from '../fn/amplify/get-amplify-credits-rates';
import { getAmplifyCreditsTotal } from '../fn/amplify/get-amplify-credits-total';
import { GetAmplifyCreditsTotal$Params } from '../fn/amplify/get-amplify-credits-total';
import { saveStripeAmplifyCreditsPurchaseSessionDetailsFailed } from '../fn/amplify/save-stripe-amplify-credits-purchase-session-details-failed';
import { SaveStripeAmplifyCreditsPurchaseSessionDetailsFailed$Params } from '../fn/amplify/save-stripe-amplify-credits-purchase-session-details-failed';
import { saveStripeAmplifyCreditsPurchaseSessionDetailsSuccess } from '../fn/amplify/save-stripe-amplify-credits-purchase-session-details-success';
import { SaveStripeAmplifyCreditsPurchaseSessionDetailsSuccess$Params } from '../fn/amplify/save-stripe-amplify-credits-purchase-session-details-success';

@Injectable({ providedIn: 'root' })
export class AmplifyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `amplifyPost()` */
  static readonly AmplifyPostPath = '/api/post/{id}/amplify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `amplifyPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  amplifyPost$Response(params: AmplifyPost$Params, context?: HttpContext): Observable<StrictHttpResponse<AmplifyPostResponse>> {
    return amplifyPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `amplifyPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  amplifyPost(params: AmplifyPost$Params, context?: HttpContext): Observable<AmplifyPostResponse> {
    return this.amplifyPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AmplifyPostResponse>): AmplifyPostResponse => r.body)
    );
  }

  /** Path part for operation `createAmplifyCreditsPurchaseSession()` */
  static readonly CreateAmplifyCreditsPurchaseSessionPath = '/api/amplify-credits/create-purchase-session';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAmplifyCreditsPurchaseSession()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAmplifyCreditsPurchaseSession$Response(params: CreateAmplifyCreditsPurchaseSession$Params, context?: HttpContext): Observable<StrictHttpResponse<AmplifyCreditsPurchaseSessionCreatedResponse>> {
    return createAmplifyCreditsPurchaseSession(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAmplifyCreditsPurchaseSession$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAmplifyCreditsPurchaseSession(params: CreateAmplifyCreditsPurchaseSession$Params, context?: HttpContext): Observable<AmplifyCreditsPurchaseSessionCreatedResponse> {
    return this.createAmplifyCreditsPurchaseSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<AmplifyCreditsPurchaseSessionCreatedResponse>): AmplifyCreditsPurchaseSessionCreatedResponse => r.body)
    );
  }

  /** Path part for operation `getAmplifyCreditsHasPurchasedAtLeastOnce()` */
  static readonly GetAmplifyCreditsHasPurchasedAtLeastOncePath = '/api/amplify-credits/has-purchased-at-least-once';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAmplifyCreditsHasPurchasedAtLeastOnce()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAmplifyCreditsHasPurchasedAtLeastOnce$Response(params?: GetAmplifyCreditsHasPurchasedAtLeastOnce$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return getAmplifyCreditsHasPurchasedAtLeastOnce(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAmplifyCreditsHasPurchasedAtLeastOnce$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAmplifyCreditsHasPurchasedAtLeastOnce(params?: GetAmplifyCreditsHasPurchasedAtLeastOnce$Params, context?: HttpContext): Observable<boolean> {
    return this.getAmplifyCreditsHasPurchasedAtLeastOnce$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getAmplifyCreditsRates()` */
  static readonly GetAmplifyCreditsRatesPath = '/api/amplify-credits/rates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAmplifyCreditsRates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAmplifyCreditsRates$Response(params?: GetAmplifyCreditsRates$Params, context?: HttpContext): Observable<StrictHttpResponse<AmplifyCreditsRateSummaryResponse>> {
    return getAmplifyCreditsRates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAmplifyCreditsRates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAmplifyCreditsRates(params?: GetAmplifyCreditsRates$Params, context?: HttpContext): Observable<AmplifyCreditsRateSummaryResponse> {
    return this.getAmplifyCreditsRates$Response(params, context).pipe(
      map((r: StrictHttpResponse<AmplifyCreditsRateSummaryResponse>): AmplifyCreditsRateSummaryResponse => r.body)
    );
  }

  /** Path part for operation `getAmplifyCreditsTotal()` */
  static readonly GetAmplifyCreditsTotalPath = '/api/amplify-credits/get-total';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAmplifyCreditsTotal()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAmplifyCreditsTotal$Response(params?: GetAmplifyCreditsTotal$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getAmplifyCreditsTotal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAmplifyCreditsTotal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAmplifyCreditsTotal(params?: GetAmplifyCreditsTotal$Params, context?: HttpContext): Observable<number> {
    return this.getAmplifyCreditsTotal$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `saveStripeAmplifyCreditsPurchaseSessionDetailsFailed()` */
  static readonly SaveStripeAmplifyCreditsPurchaseSessionDetailsFailedPath = '/api/amplify-credits/save-stripe-amplify-credits-purchase-session-details-failed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveStripeAmplifyCreditsPurchaseSessionDetailsFailed()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripeAmplifyCreditsPurchaseSessionDetailsFailed$Response(params: SaveStripeAmplifyCreditsPurchaseSessionDetailsFailed$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveStripeAmplifyCreditsPurchaseSessionDetailsFailed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveStripeAmplifyCreditsPurchaseSessionDetailsFailed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripeAmplifyCreditsPurchaseSessionDetailsFailed(params: SaveStripeAmplifyCreditsPurchaseSessionDetailsFailed$Params, context?: HttpContext): Observable<void> {
    return this.saveStripeAmplifyCreditsPurchaseSessionDetailsFailed$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `saveStripeAmplifyCreditsPurchaseSessionDetailsSuccess()` */
  static readonly SaveStripeAmplifyCreditsPurchaseSessionDetailsSuccessPath = '/api/amplify-credits/save-stripe-amplify-credits-purchase-session-details-success';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveStripeAmplifyCreditsPurchaseSessionDetailsSuccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripeAmplifyCreditsPurchaseSessionDetailsSuccess$Response(params: SaveStripeAmplifyCreditsPurchaseSessionDetailsSuccess$Params, context?: HttpContext): Observable<StrictHttpResponse<AmplifyCreditsPurchaseResponse>> {
    return saveStripeAmplifyCreditsPurchaseSessionDetailsSuccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveStripeAmplifyCreditsPurchaseSessionDetailsSuccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripeAmplifyCreditsPurchaseSessionDetailsSuccess(params: SaveStripeAmplifyCreditsPurchaseSessionDetailsSuccess$Params, context?: HttpContext): Observable<AmplifyCreditsPurchaseResponse> {
    return this.saveStripeAmplifyCreditsPurchaseSessionDetailsSuccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<AmplifyCreditsPurchaseResponse>): AmplifyCreditsPurchaseResponse => r.body)
    );
  }

}
