/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createPostPill } from '../fn/post-pill/create-post-pill';
import { CreatePostPill$Params } from '../fn/post-pill/create-post-pill';
import { deletePostPill } from '../fn/post-pill/delete-post-pill';
import { DeletePostPill$Params } from '../fn/post-pill/delete-post-pill';
import { EditorialPostPillResponse } from '../models/editorial-post-pill-response';
import { EditorialPostPillResponsePaging } from '../models/editorial-post-pill-response-paging';
import { editPostPill } from '../fn/post-pill/edit-post-pill';
import { EditPostPill$Params } from '../fn/post-pill/edit-post-pill';
import { FeedPostPillReactionResponse } from '../models/feed-post-pill-reaction-response';
import { FeedPostPillResponse } from '../models/feed-post-pill-response';
import { getEditorialPostPillById } from '../fn/post-pill/get-editorial-post-pill-by-id';
import { GetEditorialPostPillById$Params } from '../fn/post-pill/get-editorial-post-pill-by-id';
import { getEditorialPostPills } from '../fn/post-pill/get-editorial-post-pills';
import { GetEditorialPostPills$Params } from '../fn/post-pill/get-editorial-post-pills';
import { getFeedPostPills } from '../fn/post-pill/get-feed-post-pills';
import { GetFeedPostPills$Params } from '../fn/post-pill/get-feed-post-pills';
import { getPostPillReactions } from '../fn/post-pill/get-post-pill-reactions';
import { GetPostPillReactions$Params } from '../fn/post-pill/get-post-pill-reactions';
import { togglePostPillLike } from '../fn/post-pill/toggle-post-pill-like';
import { TogglePostPillLike$Params } from '../fn/post-pill/toggle-post-pill-like';

@Injectable({ providedIn: 'root' })
export class PostPillService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getEditorialPostPills()` */
  static readonly GetEditorialPostPillsPath = '/api/editorial/post-pills';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialPostPills()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialPostPills$Response(params: GetEditorialPostPills$Params, context?: HttpContext): Observable<StrictHttpResponse<EditorialPostPillResponsePaging>> {
    return getEditorialPostPills(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialPostPills$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialPostPills(params: GetEditorialPostPills$Params, context?: HttpContext): Observable<EditorialPostPillResponsePaging> {
    return this.getEditorialPostPills$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditorialPostPillResponsePaging>): EditorialPostPillResponsePaging => r.body)
    );
  }

  /** Path part for operation `createPostPill()` */
  static readonly CreatePostPillPath = '/api/editorial/post-pills';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPostPill()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createPostPill$Response(params?: CreatePostPill$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createPostPill(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPostPill$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createPostPill(params?: CreatePostPill$Params, context?: HttpContext): Observable<void> {
    return this.createPostPill$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getEditorialPostPillById()` */
  static readonly GetEditorialPostPillByIdPath = '/api/editorial/post-pills/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialPostPillById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialPostPillById$Response(params: GetEditorialPostPillById$Params, context?: HttpContext): Observable<StrictHttpResponse<EditorialPostPillResponse>> {
    return getEditorialPostPillById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialPostPillById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialPostPillById(params: GetEditorialPostPillById$Params, context?: HttpContext): Observable<EditorialPostPillResponse> {
    return this.getEditorialPostPillById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditorialPostPillResponse>): EditorialPostPillResponse => r.body)
    );
  }

  /** Path part for operation `editPostPill()` */
  static readonly EditPostPillPath = '/api/editorial/post-pills/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editPostPill()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  editPostPill$Response(params: EditPostPill$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return editPostPill(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editPostPill$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  editPostPill(params: EditPostPill$Params, context?: HttpContext): Observable<void> {
    return this.editPostPill$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deletePostPill()` */
  static readonly DeletePostPillPath = '/api/editorial/post-pills/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePostPill()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePostPill$Response(params: DeletePostPill$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deletePostPill(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePostPill$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePostPill(params: DeletePostPill$Params, context?: HttpContext): Observable<void> {
    return this.deletePostPill$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getFeedPostPills()` */
  static readonly GetFeedPostPillsPath = '/api/post-pills';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeedPostPills()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeedPostPills$Response(params: GetFeedPostPills$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FeedPostPillResponse>>> {
    return getFeedPostPills(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFeedPostPills$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeedPostPills(params: GetFeedPostPills$Params, context?: HttpContext): Observable<Array<FeedPostPillResponse>> {
    return this.getFeedPostPills$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FeedPostPillResponse>>): Array<FeedPostPillResponse> => r.body)
    );
  }

  /** Path part for operation `getPostPillReactions()` */
  static readonly GetPostPillReactionsPath = '/api/post-pill/{id}/reactions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPostPillReactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPostPillReactions$Response(params: GetPostPillReactions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FeedPostPillReactionResponse>>> {
    return getPostPillReactions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPostPillReactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPostPillReactions(params: GetPostPillReactions$Params, context?: HttpContext): Observable<Array<FeedPostPillReactionResponse>> {
    return this.getPostPillReactions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FeedPostPillReactionResponse>>): Array<FeedPostPillReactionResponse> => r.body)
    );
  }

  /** Path part for operation `togglePostPillLike()` */
  static readonly TogglePostPillLikePath = '/api/post-pill/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `togglePostPillLike()` instead.
   *
   * This method doesn't expect any request body.
   */
  togglePostPillLike$Response(params: TogglePostPillLike$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return togglePostPillLike(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `togglePostPillLike$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  togglePostPillLike(params: TogglePostPillLike$Params, context?: HttpContext): Observable<void> {
    return this.togglePostPillLike$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
