/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getVideoCall } from '../fn/video-call/get-video-call';
import { GetVideoCall$Params } from '../fn/video-call/get-video-call';
import { VideoCallResponse } from '../models/video-call-response';

@Injectable({ providedIn: 'root' })
export class VideoCallService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVideoCall()` */
  static readonly GetVideoCallPath = '/api/video-call/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVideoCall()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVideoCall$Response(params: GetVideoCall$Params, context?: HttpContext): Observable<StrictHttpResponse<VideoCallResponse>> {
    return getVideoCall(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVideoCall$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVideoCall(params: GetVideoCall$Params, context?: HttpContext): Observable<VideoCallResponse> {
    return this.getVideoCall$Response(params, context).pipe(
      map((r: StrictHttpResponse<VideoCallResponse>): VideoCallResponse => r.body)
    );
  }

}
