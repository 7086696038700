import { DestroyRef, inject, Injectable } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication';
import { distinctUntilChanged } from 'rxjs/operators';
import { map, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ClarityWindow } from '@app/core/ms-clarity';

@Injectable({
    providedIn: 'root',
})
export class MsClarityService {
    private readonly destroyRef = inject(DestroyRef);

    constructor(private authService: AuthenticationService) {}

    public init(): void {
        this.authService.identity$
            .pipe(
                map((user) => user?.id),
                distinctUntilChanged(),
                tap((userId) => {
                    const clarityWindow = window as unknown as ClarityWindow;
                    clarityWindow.clarity('identify', (userId ?? '').toString());
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }
}
