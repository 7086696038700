/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { authenticatedEndpointTest } from '../fn/auth/authenticated-endpoint-test';
import { AuthenticatedEndpointTest$Params } from '../fn/auth/authenticated-endpoint-test';
import { checkSession } from '../fn/auth/check-session';
import { CheckSession$Params } from '../fn/auth/check-session';
import { forgotPassword } from '../fn/auth/forgot-password';
import { ForgotPassword$Params } from '../fn/auth/forgot-password';
import { getProfile } from '../fn/auth/get-profile';
import { GetProfile$Params } from '../fn/auth/get-profile';
import { loginPost2Fa } from '../fn/auth/login-post-2-fa';
import { LoginPost2Fa$Params } from '../fn/auth/login-post-2-fa';
import { loginPre2Fa } from '../fn/auth/login-pre-2-fa';
import { LoginPre2Fa$Params } from '../fn/auth/login-pre-2-fa';
import { logout } from '../fn/auth/logout';
import { Logout$Params } from '../fn/auth/logout';
import { ProfileResponse } from '../models/profile-response';
import { resetPassword } from '../fn/auth/reset-password';
import { ResetPassword$Params } from '../fn/auth/reset-password';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authenticatedEndpointTest()` */
  static readonly AuthenticatedEndpointTestPath = '/api/auth/authenticated-endpoint-test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticatedEndpointTest()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticatedEndpointTest$Response(params?: AuthenticatedEndpointTest$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return authenticatedEndpointTest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticatedEndpointTest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticatedEndpointTest(params?: AuthenticatedEndpointTest$Params, context?: HttpContext): Observable<string> {
    return this.authenticatedEndpointTest$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `checkSession()` */
  static readonly CheckSessionPath = '/api/auth/check-session';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkSession$Response(params?: CheckSession$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return checkSession(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkSession(params?: CheckSession$Params, context?: HttpContext): Observable<void> {
    return this.checkSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `forgotPassword()` */
  static readonly ForgotPasswordPath = '/api/auth/forgot-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forgotPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPassword$Response(params: ForgotPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return forgotPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `forgotPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPassword(params: ForgotPassword$Params, context?: HttpContext): Observable<void> {
    return this.forgotPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getProfile()` */
  static readonly GetProfilePath = '/api/auth/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile$Response(params?: GetProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<ProfileResponse>> {
    return getProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile(params?: GetProfile$Params, context?: HttpContext): Observable<ProfileResponse> {
    return this.getProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProfileResponse>): ProfileResponse => r.body)
    );
  }

  /** Path part for operation `loginPost2Fa()` */
  static readonly LoginPost2FaPath = '/api/auth/login-post-2fa';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loginPost2Fa()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginPost2Fa$Response(params: LoginPost2Fa$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return loginPost2Fa(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loginPost2Fa$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginPost2Fa(params: LoginPost2Fa$Params, context?: HttpContext): Observable<void> {
    return this.loginPost2Fa$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `loginPre2Fa()` */
  static readonly LoginPre2FaPath = '/api/auth/login-pre-2fa';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loginPre2Fa()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginPre2Fa$Response(params: LoginPre2Fa$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return loginPre2Fa(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loginPre2Fa$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginPre2Fa(params: LoginPre2Fa$Params, context?: HttpContext): Observable<void> {
    return this.loginPre2Fa$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `logout()` */
  static readonly LogoutPath = '/api/auth/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logout()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout$Response(params?: Logout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return logout(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout(params?: Logout$Params, context?: HttpContext): Observable<void> {
    return this.logout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `resetPassword()` */
  static readonly ResetPasswordPath = '/api/auth/reset-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword$Response(params: ResetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword(params: ResetPassword$Params, context?: HttpContext): Observable<void> {
    return this.resetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
