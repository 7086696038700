import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { ACTIVE_CAMPAIGN_ACCOUNT_ID } from './tokens/active-campaign-project-id.token';

@NgModule({})
export class ActiveCampaignModule {
    constructor(@Inject(ACTIVE_CAMPAIGN_ACCOUNT_ID) accountId: string | undefined) {
        if (!accountId) return;

        // NOTE: this is a porting of the script tag from the original active campaign script

        // Create script element
        /* eslint-disable max-len */
        const scriptContent = `
             (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
            vgo('setAccount', '${accountId}');
            vgo('setTrackByDefault', true);
            vgo('process');
        `;
        /* eslint-enable max-len */

        // Create a script element
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.text = scriptContent;

        // Insert script element before the first script tag in the document
        const firstScriptElement = document.getElementsByTagName('script')[0];
        firstScriptElement.parentNode?.insertBefore(scriptElement, firstScriptElement);
    }

    static forRoot(config: { accountId?: string }): ModuleWithProviders<ActiveCampaignModule> {
        return {
            ngModule: ActiveCampaignModule,
            providers: [
                {
                    provide: ACTIVE_CAMPAIGN_ACCOUNT_ID,
                    useValue: config.accountId,
                },
            ],
        };
    }
}
